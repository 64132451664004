import React from 'react';
import { Typography, Grid } from '@material-ui/core';

const Bio = () => {
    return (
        <Grid container direction="column">
            <Typography variant="h4">Bio</Typography>
            <Typography>
                Professional and diligent MERN Developer with a track record of delivering
                high redundancy and performance, critical infrastructure to the NHS across
                the United Kingdom, Ireland, Germany and Canada. Delivering legacy and
                MERN solutions to the Fire Services and Coastguard across the United
                Kingdom & Ireland. Every role undertaken has always been taken with the
                interest to bring value. Strong cryptography and privacy awareness.
                Complete understanding of Agile workflows and development lifecycles.
            </Typography>
        </Grid>
    )
}

export default Bio