import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Grid, Button, Tooltip, Typography } from '@material-ui/core'

const Skill = ({ title, icon }) => {
    return (
        <Tooltip title={title}>
            <Typography variant="h4" color="secondary">
                <i class={icon} />
            </Typography>
        </Tooltip>
    )
}

const Socials = () => {
    const skills = [
        {
            title: 'JavaScript',
            icon: 'devicon-javascript-plain'
        }, {
            title: 'MongoDB',
            icon: 'devicon-mongodb-plain'
        }, {
            title: 'Electron',
            icon: 'devicon-electron-original'
        }, {
            title: 'React',
            icon: 'devicon-react-original'
        }, {
            title: 'NodeJS',
            icon: 'devicon-nodejs-plain'
        }, {
            title: 'Typescript',
            icon: 'devicon-typescript-plain'
        }, {
            title: 'AWS',
            icon: 'devicon-amazonwebservices-original'
        },{
            title: 'CSS3',
            icon: 'devicon-css3-plain'
        },{
            title: 'MySql',
            icon: 'devicon-mysql-plain'
        },
    ]
    return (
        <Grid container justify="center">
            <Grid justify="center" container direction="row">

                <Tooltip title="Connect on LinkedIn">
                    <a href="https://www.linkedin.com/in/james-robert-allen/">
                        <Button color="secondary" aria-label="Connect on LinkedIn" startIcon={<LinkedInIcon />} >
                            LinkedIn
                    </Button>
                    </a>
                </Tooltip>
                <Tooltip title="View on GitHub">
                    <a href="https://github.com/DeBrie">
                        <Button color="secondary" aria-label="View on GitHub" startIcon={<GitHubIcon />}>
                            GitHub
                    </Button>
                    </a>
                </Tooltip>
            </Grid>
            <Grid justify="center" container direction="row">
                {skills.map(({ title, icon }) => 
                    <Skill title={title} icon={icon} />
                )}
            </Grid>
        </Grid>
    )
}

export default Socials;