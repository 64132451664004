import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange'
import PinDropIcon from '@material-ui/icons/PinDrop'
import WorkIcon from '@material-ui/icons/Work';
import GroupIcon from '@material-ui/icons/Group';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import ListAltIcon from '@material-ui/icons/ListAlt';
import SubjectIcon from '@material-ui/icons/Subject';

const ExperienceItem = ({ props }) => {
    console.log(props)
    const { companyName, location, position, dates, bio = [], responsibilities, projects } = props;
    console.log(bio && bio.split("\n"));
    return (
        <Grid container direction="column" style={{ paddingBottom: '2rem' }} >
            <Typography variant="h5" color="primary">
                <GroupIcon fontSize="small" color="secondary" /> {position}
            </Typography>
            <Typography variant="h6" color="textSecondary">
                <WorkIcon fontSize="small" color="secondary" /> {companyName}
            </Typography>
            <Typography>
                <PinDropIcon fontSize="small" color="secondary" /> {location}
            </Typography>
            <Typography>
                <DateRangeIcon fontSize="small" color="secondary" /> {dates}
            </Typography>
            <Typography >
                <SubjectIcon fontSize="small" color="secondary" /> Description
            </Typography>
            {
                bio.split("\n").map(line =>
                    <Typography >
                        {line}
                    </Typography>
                )
            }
            {
                responsibilities
                &&
                <Typography >
                    <ListAltIcon fontSize="small" color="secondary" /> Responsibilities:
                </Typography>
            }
            {
                responsibilities
                &&
                responsibilities.map((responsibility, inx) =>
                    <Typography >
                        {responsibility}{inx !== responsibilities.length - 1 ? "," : ""}
                    </Typography>
                )
            }

            {
                projects
                &&
                <Typography >
                    <EmojiEventsIcon fontSize="small" color="secondary" /> Projects:
                </Typography>
            }
            {
                projects
                &&
                projects.map((project, inx) =>
                    <Typography>
                        {project} {inx !== projects.length - 1 ? "," : ""}
                    </Typography>
                )
            }
        </Grid>
    )
}

const multitoneBio = `
I am currently responsible for creating an entire new product to integrate and eventually replace a legacy stack using MERN technologies. I have aided in development of many current, widely used systems using a host of communication protocols.
To date I have increased throughput of communications considerably and created translation applications to connect systems that were previously not able to communicate.
In this role I have worked with the FHIR HL7 standard to allow secure messaging platforms to communicate with patient records allowing doctors and nurses to discreetly share patient information amongst one another.
`

const Experience = () => {
    const exp = [
        {
            companyName: "Multitone Electronics Plc",
            location: "Basingstoke, Hampshire",
            position: "Full Stack Developer",
            dates: "August 2018 - Present",
            bio: multitoneBio,
            responsibilities: [
                'Delivering responsive, high redundancy applications for critical infrastructures on time',
                'Migrating software stacks to be cloud operable',
                'Updating and maintaining the UK & Ireland Coastguard & Fire Departments mobilisation system',
                'Managing other developers on projects to create bespoke solutions from customer requirements',
                'Creating demonstrable feasibility studies for skunkwork projects',
                'Aiding SQA create automation testing to save time'
            ],
            projects: [
                'Multitone OAuth2 Server',
                'Replacement full stack framework to replace existing Flagship product',
                'Multitone iMessage (Flagship product)',
                'Multitone Communication Suite (Legacy networks across the NHS & Fire Departments)'
            ]
        },
        {
            companyName: "Costa Coffee",
            location: "Broadstairs, Kent",
            position: "Acting Store Manager",
            dates: "April 2018 - August 2018",
            bio: "I managed the deployment of 8 members of staff for management cover at one of the busiest Costa Coffees in the country.",
            responsibilities: [
                "Day To Day Running",
                "Managing Staff",
                "Coaching",
                "P&L",
                "Hiring"
            ]
        },
        {
            companyName: "Costa Coffee",
            location: "Broadstairs, Kent",
            position: "Assistant Manager",
            dates: "July 2017 - April 2018",
            bio: "I started here as a barista after university, but was quickly advanced to Assistant Manager"
        }
    ]

    return (
        <Grid>
            <Typography variant="h4">
                Experience
            </Typography>
            {
                exp.map(experience =>
                    <ExperienceItem props={experience} />
                )
            }
        </Grid>
    )
}

export default Experience;