import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            dark: '#a31545',
            main: '#e91e63',
            light: '#ed4b82',
            contrastText: '#fff',
        },
        secondary: {
            dark: '#b22c5a',
            main: '#ff4081',
            light: '#ff669a',
            contrastText: '#000',
        },
    },
});

export default theme;