import React from 'react'
import { Avatar, Container, Grid, Typography } from '@material-ui/core'
import Bio from './components/Bio'
import Education from './components/Education'
import Experience from './components/Experience'
import Socials from './components/Socials'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    padTop: {
        paddingTop: '2rem'
    }
}));

const App = () => {
    const classes = useStyles();
    const edu = [
        {
            school: "Canterbury Christ Church University",
            location: "Kent",
            grade: "Computing BSc, Upper Second Class with Honors",
            years: "2014-2017"
        },
        {
            school: "Ursuline 6th Form",
            location: "Westgate on Sea, Kent",
            grade: "3 A-Levels C or above",
            years: "2011-2014"
        }
    ]
    return (
        <Container className={classes.padTop}>
            <Grid container spacing={4}>
                <Grid container xs={12} direction="column" alignItems="center">
                    <Avatar src={`/static/images/141091025_424534785334675_7560323965032390234_n.png`} className={classes.large} />
                    <Typography variant="h2">James Allen</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Socials />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Bio />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Education />
                </Grid>
                <Grid item xs={12}>
                    <Experience />
                </Grid>
            </Grid>
        </Container>
    )
}

export default App;