import React from 'react';
import { Grid, Typography } from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import SchoolIcon from '@material-ui/icons/School'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import PinDropIcon from '@material-ui/icons/PinDrop'

const EducationItem = ({ school, location, grade, years }) => {
    return (
        <Grid container direction="column" style={{ paddingBottom: '2rem' }}>
            <Typography variant="h5">
                <SchoolIcon fontSize="small" color="secondary"/> {school}
            </Typography>
            <Typography variant="h6">
                <EmojiEventsIcon fontSize="small" color="secondary"/> {grade}
            </Typography>
            <Typography>
                <PinDropIcon fontSize="small" color="secondary"/> {location}
            </Typography>
            <Typography>
                <DateRangeIcon fontSize="small" color="secondary"/> {years}
            </Typography>
        </Grid>
    )
}

const Education = () => {
    const edu = [
        {
            school: "Canterbury Christ Church University",
            location: "Kent",
            grade: "Computing BSc, Upper Second Class with Honors",
            years: "2014-2017"
        },
        {
            school: "Ursuline 6th Form",
            location: "Westgate on Sea, Kent",
            grade: "3 A-Levels C or above",
            years: "2011-2014"
        }
    ]
    return (
        <Grid container direction="column" >
            <Typography variant="h4">
                Education
            </Typography>
            {
                edu.map(({ school, location, grade, years }) =>
                    <EducationItem school={school} location={location} grade={grade} years={years} />
                )
            }
        </Grid>
    )
}

export default Education;